<template>
  <div class="network">
    <navBar></navBar>

    <textEdit title-user="Network Main Text" title-intern-prop="network" site-prop="network"></textEdit>
    <textEdit title-user="Network Additional Text" title-intern-prop="network_special" site-prop="network"></textEdit>
    <div class="content">
      <h1>Lists</h1>
      <div class="head" v-if="showHead">
        <md-field>
          <label for="heading">Heading</label>
          <md-select @md-selected="addListDiv = false" v-model="index" name="Heading" id="heading">
            <md-option v-for="(element, index) in list" v-bind:key="index" :value="index">{{
                element.titleIntern
              }}
            </md-option>
          </md-select>
        </md-field>
        <md-button class="md-primary md-raised" @click="showAddListDiv()">Add Bullet List</md-button>
      </div>

      <div class="listData" v-if="!addListDiv && index != null">
        <!-- List-->
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Überschrift der Liste</label>
                <md-input v-model="list[index].de.title"></md-input>
              </md-field>
              <br/>
              <md-field>
                <label>Elemente</label>
                <md-input v-model="newElement.de.subtitle" placeholder="Neues Element - Überschrift"></md-input>
                <md-input v-model="newElement.de.text" placeholder="Neues Element - Text"></md-input>
                <md-button class="noMargin" @click="addElementToExisting('de', index)">Hinzufügen</md-button>
              </md-field>
              <div v-for="(element, i) in list[index].de.content" v-bind:key="i"
                   style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span><b>{{ element.subtitle }}</b> {{ element.text }}</span> <span
                  @click="list[index].de.content.splice(i, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Header of the Year</label>
                <md-input v-model="list[index].en.title"></md-input>
              </md-field>
              <br/>
              <md-field>
                <label>Elements</label>
                <md-input v-model="newElement.en.subtitle" placeholder="New Element - Heading"></md-input>
                <md-input v-model="newElement.en.text" placeholder="New Element - Text"></md-input>
                <md-button class="noMargin" @click="addElementToExisting('en', index)">Add</md-button>
              </md-field>
              <div v-for="(element, i) in list[index].en.content" v-bind:key="i"
                   style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span><b>{{ element.subtitle }}</b> {{ element.text }}</span> <span
                  @click="list[index].en.content.splice(i, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>
          </tr>
        </table>

        <md-button class="md-primary md-raised" @click="putList(index)">Save</md-button>

        <md-button class="md-accent md-raised" @click="deleteList(index)">Delete</md-button>

      </div>

      <div class="addList" v-if="addListDiv">
        <md-field>
          <label>Intern Title*</label>
          <md-input v-model="newList.titleIntern"></md-input>
        </md-field>
        <br/>
        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Überschrift der Liste</label>
                <md-input v-model="newList.de.title"></md-input>
              </md-field>
              <br/>
              <md-field>
                <label>Elemente</label>
                <md-input v-model="newElement['de']['subtitle']" placeholder="Neues Element - Überschrift"></md-input>
                <md-input v-model="newElement['de']['text']" placeholder="Neues Element - Text"></md-input>
                <md-button class="noMargin" @click="addElement('de')">Hinzufügen</md-button>
              </md-field>
              <div v-for="(element, i) in newList.de.content" v-bind:key="i"
                   style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span><b>{{ element.subtitle }}</b> {{ element.text }}</span> <span
                  @click="newList.de.content.splice(i, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Header of the Year</label>
                <md-input v-model="newList.en.title"></md-input>
              </md-field>
              <br/>
              <md-field>
                <label>Elements</label>
                <md-input v-model="newElement['en']['subtitle']" placeholder="New Element - Heading"></md-input>
                <md-input v-model="newElement['en']['text']" placeholder="New Element - Text"></md-input>
                <md-button class="noMargin" @click="addElement('en')">Add</md-button>
              </md-field>
              <div v-for="(element, i) in newList.en.content" v-bind:key="i"
                   style="width: 100%; padding: 0px 20px 0px 20px; display: flex; flex-direction: row; justify-content: space-between;">
                <span><b>{{ element.subtitle }}</b> {{ element.text }}</span> <span
                  @click="newList.en.content.splice(i, 1)"><md-icon>delete</md-icon></span>
              </div>
            </td>
          </tr>
        </table>
        <div style="font-size: .8em">*Network site contains following internal titles:
          <ul>
            <li>
              networkParticipants2019
            </li>
            <li>
              networkParticipants2018
            </li>
            <li>
              networkParticipants(year, 2014 - 2025)
            </li>
            <li>
              networkFixedContract
            </li>
            <li>
              networkGuestContract
            </li>
            <li>
              networkYoungEngagement
            </li>
            <li>
              networkConcertRecital
            </li>
            <li>
              networkCompetitionPrices
            </li>
            <li>
              networkManagement
            </li>
          </ul>
        </div>
        <md-list>

        </md-list>


        <md-dialog-confirm
            :md-active.sync="newListDialogActive"
            md-title="Do you want to add a new list?"
            md-content="A new data set will be created."
            md-confirm-text="Save"
            md-cancel-text="Cancel"
            @d-cancel="newListDialogActive = false"
            @md-confirm="postNewList()"/>

        <md-button class="md-primary md-raised" @click="newListDialogActive = true">Add List</md-button>

        <md-dialog-confirm
            :md-active.sync="newListCancelDialogActive"
            md-title="Do you want to delete all entered data?"
            md-content="All data you entered will be deleted."
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @d-cancel="newListCancelDialogActive = false"
            @md-confirm="resetSite()"/>

        <md-button class="md-accent md-raised" @click="newListCancelDialogActive = true">Cancel</md-button>
        <loading :active.sync="query"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
      </div>


      <loading :active.sync="query"
               :can-cancel="true"
               :is-full-page="true"></loading>
    </div>
  </div>
</template>
<script>

import navBar from '@/components/NavBar.vue';
import textEdit from '@/components/TextEdit.vue'
import axios from 'axios'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/const.js'

export default {
  name: "Network",
  data() {
    return {
      config: config,
      showHead: true,
      addListDiv: false,
      index: null,

      query: false,
      currentListName: '',


      newListDialogActive: false,
      newListCancelDialogActive: false,
      deleteListDialogActive: false,

      list: [],
      newList: {
        titleIntern: "",
        "de": {
          title: "",
          content: []
        },
        "en": {
          title: "",
          content: []
        },
        site: "network",
      },
      newElement: {
        "de": {
          subtitle: '',
          text: ''
        },
        "en": {
          subtitle: '',
          text: ''
        }
      },
      networkText: null,

      newNetworkText: {
        titleIntern: "network",
        "de": {
          title: "",
          content: ""
        },
        "en": {
          title: "",
          content: ""
        },
        site: "network",
      },
    }
  },
  methods: {
    showAddYearDiv() {
      this.addListDiv = true
      this.showHead = false
    },
    showAddListDiv() {
      this.addListDiv = true
      this.showHead = false
    },
    loadDataList: function () {
      const me = this
      axios.get(me.config.SERVER_ADDRESS + '/list')
          .then(function (response) {
            me.list = response.data
            // console.log(me.list)
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    deleteList(index) {
      this.query = true;

      let me = this;
      axios.delete(me.config.SERVER_ADDRESS + '/list/' + me.list[index]._id)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite();
            me.query = false;
          })
          .catch(function (error) {
            // console.log(error);
            me.query = false;
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    addElement(language) {
      switch (language) {
        case 'de':
          this.newList.de.content.push(this.newElement.de);
          this.newElement.de = {
            subtitle: '',
            text: ''
          };
          break;
        case 'en':
          this.newList.en.content.push(this.newElement.en);
          this.newElement.en = {
            subtitle: '',
            text: ''
          };
          break;
      }
    },
    addElementToExisting(language, index) {
      switch (language) {
        case 'de':
          this.list[index].de.content.push(this.newElement.de);
          this.newElement.de = {
            subtitle: '',
            text: ''
          };
          break;
        case 'en':
          this.list[index].en.content.push(this.newElement.en);
          this.newElement.en = {
            subtitle: '',
            text: ''
          };
          break;
      }
    },
    postNewList() {
      this.query = true;
      let me = this;
      // console.log(JSON.stringify(me.newList));
      axios.post(me.config.SERVER_ADDRESS + '/list', me.newList)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
            me.loadDataList()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    putList() {
      this.query = true;
      let me = this;
      // console.log(me.list[me.index]._id)
      // console.log(JSON.stringify(me.list[me.index]))
      axios.put(me.config.SERVER_ADDRESS + '/list/id/' + me.list[me.index]._id, me.list[me.index])
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
            me.loadDataList()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
    },
    loadDataText: function () {
      const me = this
      me.query = true
      axios.get(me.config.SERVER_ADDRESS + '/text/name/network')
          .then(function (response) {
            me.networkText = response.data[0]
            if (!me.networkText)
              me.networkText = me.newNetworkText
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    putText() {
      if (this.networkText._id == null) {
        // console.log("New text is going to get created.")
        this.postText()
      } else {
        // console.log("Editing text with id " + this.networkText._id)
        this.query = true;
        let me = this;
        // console.log(JSON.stringify(me.networkText))
        // console.log(me.config.SERVER_ADDRESS + '/text/id/' + me.networkText._id)

        axios.put(me.config.SERVER_ADDRESS + '/text/id/' + me.networkText._id, me.networkText)
            // eslint-disable-next-line no-unused-vars
            .then(function (response) {
              // console.log(response.data.message);
              me.resetSite()
              me.loadDataText()
            })
            .catch(function (error) {
              alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
            })
            .finally(() => {
              me.query = false
            })
      }
    },
    postText() {
      // console.log(JSON.stringify(this.networkText))

      this.query = true;
      let me = this;
      axios.post(me.config.SERVER_ADDRESS + '/text', me.networkText)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
            me.loadDataText()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          })
          .finally(() => {
            me.query = false
          })
    },
    deleteText() {
      this.query = true;

      let me = this;
      axios.delete(me.config.SERVER_ADDRESS + '/text/' + me.networkText._id)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite();
            me.query = false;
          })
          .catch(function (error) {
            // console.log(error);
            me.query = false;
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    resetSite: function () {
      this.showHead = true
      this.addListDiv = false
      this.index = null

      this.applyText = null
      this.query = false
      this.currentListName = ''


      this.newListDialogActive = false
      this.newListCancelDialogActive = false
      this.deleteListDialogActive = false

      this.list = []
      this.newList = {
        titleIntern: "",
        "de": {
          title: "",
          content: []
        },
        "en": {
          title: "",
          content: []
        },
        site: "network",
      }
      this.newElement = {
        titleIntern: "",
        "de": {
          subtitle: '',
          text: ''
        },
        "en": {
          subtitle: '',
          text: ''
        }
      }
      this.applyText = null
      this.loadDataText()
      this.loadDataList()
    },

  },
  components: {
    navBar,
    Loading,
    textEdit
  },
  mounted() {
    this.loadDataText()
    this.loadDataList()
  }
}
</script>
<style scoped>
.content {
  margin: 2% 5% 0 5%;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

td {
  padding: 1%;
  width: 50%;
}

.noMargin {
  margin: 0px;
}
</style>
